
import Handsontable from 'handsontable';
import { PropType, defineComponent } from 'vue';

import TableLoading from '@/shared/handsontable/components/TableLoading.vue';
import hiddenColumnSettings from '@/shared/handsontable/rework/features/hiddenColumns/settings';
import { settings as nestedTableSettings } from '@/shared/handsontable/rework/features/nestedTable';
import { ColumnSettingsFlattenedSubtableWithUserSettings } from '@/shared/handsontable/rework/features/nestedTable/types';
import { ColumnSettingsWithUserSettings } from '@/shared/handsontable/rework/types';

import TableWrapper from './TableWrapper.vue';

export default defineComponent({
  name: 'TableActivitiesLoading',
  components: { TableWrapper, TableLoading },
  props: {
    columns: {
      type: Array as PropType<ColumnSettingsWithUserSettings[]>,
      required: true,
    },
  },
  computed: {
    tableColumns(): ColumnSettingsFlattenedSubtableWithUserSettings[] {
      const flattenedColumns = nestedTableSettings.columns(this.columns);
      const hiddenColumns = hiddenColumnSettings.hiddenColumns(flattenedColumns);
      const visibleColumns = this.filterHiddenColumns(flattenedColumns, hiddenColumns);

      return visibleColumns;
    },
  },
  methods: {
    filterHiddenColumns(
      flattenedColumns: ColumnSettingsFlattenedSubtableWithUserSettings[],
      hiddenColumns: Handsontable.GridSettings['hiddenColumns'],
    ): ColumnSettingsFlattenedSubtableWithUserSettings[] {
      if (hiddenColumns === undefined || typeof hiddenColumns === 'boolean') return flattenedColumns;

      return flattenedColumns.filter((column, index) => !hiddenColumns.columns?.includes(index));
    },
  },
});
