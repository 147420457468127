import { GetterTree } from 'vuex';

import { SubscribableGetters } from '@/shared/mixins/store/subscribableData/getters';
import { RootState } from '@/store/types';

import type { TableDataHistory } from '../handsontable/types';
import { subscribableStore } from './common';
import type { DaturaHistoryEntry, DaturaHistoryState } from './types';

export type Getters = SubscribableGetters<DaturaHistoryEntry> & {
  list: DaturaHistoryEntry[];
  tabular: TableDataHistory[];
};

const getterTree: GetterTree<DaturaHistoryState, RootState> = {
  ...subscribableStore.getters,
  list(state: DaturaHistoryState): DaturaHistoryEntry[] {
    return Object.values<DaturaHistoryEntry>(state.data);
  },
  tabular(state: DaturaHistoryState, getters: Getters): TableDataHistory[] {
    // TODO: keep the table state in store
    return getters.list.map((e) => ({ ...e, select: false }));
  },
};

export default getterTree;
