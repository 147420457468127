import Handsontable from 'handsontable';
import numbro from 'numbro';
import Vue from 'vue';

import { ColumnSettingsCheckboxOptional } from '@/shared/handsontable/renderers/types';
import { columnSorting as columnSortingDate } from '@/shared/handsontable/rework/cellTypes/date';
import { ColumnSettingsOptional } from '@/shared/handsontable/rework/cellTypes/optionalRenderer/types';
import defaultPlaceholderColumn from '@/shared/handsontable/rework/features/placeholderColumn/column';
import { FarmdokColumnSettings } from '@/shared/handsontable/rework/types';
import store from '@/store';

import CellValue = Handsontable.CellValue;

const t = Vue.i18n.translate;

export const id: Handsontable.ColumnSettings = {
  data: 'id',
  header: {
    title: 'ID',
  },
  hiddenInPageSettings: true,
  hidden: true,
  width: 200,
};

export const selectColumn: ColumnSettingsCheckboxOptional = {
  data: 'select',
  type: 'farmdok.checkbox.optional',
  noHeaderContextMenu: true,
  hiddenInPageSettings: true,
  hidden: false,
  width: 45,
  disableColumnResize: true,
};

export const createdOn: ColumnSettingsOptional = {
  data: 'creationDate',
  header: {
    title: t('Erstellt'),
  },
  readOnly: true,
  type: 'date',
  width: 120,
  hiddenInPageSettings: false,
  hidden: false,
  columnSorting: columnSortingDate,
  initialSortOrder: 'desc',
  className: 'bg-transparent',
};

export const fieldColumn: ColumnSettingsOptional = {
  data: 'fieldId',
  type: 'text',
  header: {
    title: t('FSNr-Schlag'),
  },
  readOnly: false,
  width: 300,
  hiddenInPageSettings: false,
  hidden: false,
  className: 'bg-transparent',
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    const fieldName = store.getters['fields/fieldDisplayName'](value);
    // eslint-disable-next-line no-param-reassign
    td.innerText = fieldName;
    return td;
  },
};

export const assessmentName: ColumnSettingsOptional = {
  data: 'additionalData.assessmentName',
  type: 'text',
  header: {
    title: t('Assessment Name'),
  },
  readOnly: true,
  width: 300,
  hiddenInPageSettings: false,
  hidden: false,
  className: 'bg-transparent',
};

export const occurrence: ColumnSettingsOptional = {
  data: 'additionalData.occurrencesPerHa',
  header: {
    title: t('Häufigkeit'),
    description: t('Vorkommen pro Hektar'),
  },
  type: 'numeric',
  readOnly: true,
  width: 120,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = numbro(value ?? 0).format({
      mantissa: 1,
    });
    return td;
  },
};

export const total: ColumnSettingsOptional = {
  data: 'additionalData.occurrencesTotal',
  header: {
    title: t('Gesamtanzahl'),
    description: t('Vorkommen am Feld'),
  },
  type: 'numeric',
  readOnly: true,
  width: 120,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = numbro(value ?? 0).format({
      mantissa: 0,
    });
    return td;
  },
};

export const riskCategory: ColumnSettingsOptional = {
  data: 'additionalData.riskCategory',
  header: {
    title: t('Risikokategorie'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 100,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    td.innerText = t(value) ?? '-';
    return td;
  },
};

export const link: ColumnSettingsOptional = {
  data: 'link',
  header: {
    title: t('Link'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 300,
  hiddenInPageSettings: false,
  hidden: false,
  className: 'bg-transparent',
};

export const customerName: ColumnSettingsOptional = {
  data: 'fieldId',
  header: {
    title: t('Kunde'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 200,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    const field = store.state.fields.data[value];
    const customer = store.state.customers.data[field.fieldGroup.customerId ?? ''];
    // eslint-disable-next-line no-param-reassign
    td.innerText = customer?.name ?? '-';
    return td;
  },
};

export const customerNumber: ColumnSettingsOptional = {
  data: 'fieldId',
  header: {
    title: t('Kundennummer'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 200,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    const field = store.state.fields.data[value];
    const customer = store.state.customers.data[field.fieldGroup.customerId ?? ''];
    // eslint-disable-next-line no-param-reassign
    td.innerText = (customer?.customerNumber as string) ?? '-';
    return td;
  },
};

export const companyNumber: ColumnSettingsOptional = {
  data: 'fieldId',
  header: {
    title: t('Betriebsnummer'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 200,
  hiddenInPageSettings: false,
  hidden: false,
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    const field = store.state.fields.data[value];
    const customer = store.state.customers.data[field.fieldGroup.customerId ?? ''];
    // eslint-disable-next-line no-param-reassign
    td.innerText = customer?.bdmId ?? '-';
    return td;
  },
};

export const customerEmail: ColumnSettingsOptional = {
  data: 'fieldId',
  header: {
    title: t('Kontakt E-Mail'),
  },
  type: 'text',
  required: true,
  readOnly: true,
  width: 300,
  hiddenInPageSettings: false,
  hidden: false,
  columnSorting: columnSortingDate,
  initialSortOrder: 'desc',
  renderer(
    instance: Handsontable,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: CellValue,
  ): HTMLTableCellElement | void {
    // eslint-disable-next-line no-param-reassign
    const field = store.state.fields.data[value];
    const customer = store.state.customers.data[field.fieldGroup.customerId ?? ''];
    // eslint-disable-next-line no-param-reassign
    td.innerText = customer?.email ?? '-';
    return td;
  },
};

const placeholderColumn = { ...defaultPlaceholderColumn, width: 40 };

export const columns: FarmdokColumnSettings[] = [
  id,
  selectColumn,
  createdOn,
  fieldColumn,
  assessmentName,
  occurrence,
  total,
  riskCategory,
  link,
  customerName,
  customerNumber,
  companyNumber,
  customerEmail,
  placeholderColumn,
];

export default columns;
