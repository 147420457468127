import getDisplayValue, { getColumnKey } from './getDisplayValue';

/**
 * returns a list of all array indices that DO NOT contain the needle
 * filters a list of entries (objects)
 * checks only the name attribute of each entry, if it contains the given needle (case unsenstive)
 *
 * @param needle {String}
 * @param data {Array}
 * @returns {Array}
 */
export function filterByName(needle = '', data = []) {
  if (needle.length === 0) {
    return [];
  }
  const entriesToFilterOut = [];
  data.forEach((entry, index) => {
    if (entry == null || typeof entry.name !== 'string' || !entry.name.toLowerCase().includes(needle.toLowerCase())) {
      entriesToFilterOut.push({ entry, index });
    }
  });
  return entriesToFilterOut;
}

/**
 * Stringifies both values and then compares them according to the type:<br>
 * Valid filter types: contains, not, greater, less, equals
 *
 * @param value
 * @param needle
 * @param type
 * @returns {boolean}
 */
export function compareValuesByFilterType(value, needle, type) {
  const comparableValueA = String(value).toLowerCase();
  const comparableValueB = String(needle).toLowerCase();
  if (type === 'contains') {
    return comparableValueA.includes(comparableValueB);
  }
  if (type === 'not') {
    return !comparableValueA.includes(comparableValueB);
  }
  if (type === 'equals') {
    return comparableValueA === comparableValueB;
  }
  if (type === 'greater') {
    return comparableValueA > comparableValueB;
  }
  if (type === 'less') {
    return comparableValueA < comparableValueB;
  }
  return comparableValueA.includes(comparableValueB);
}

/**
 * Returns a list of all data entries that DO NOT match ALL filters.<br>
 * It looks for the "needle" in every column and checks all filters in "filtersByColumnKey" in the columns matching the key:
 * <pre>
 *   {
 *     name: { value: 'foo', type: 'contains' },
 *     crop: { value: 'bar', type: 'exact' },
 *   }
 * </pre>
 * Uses 'toString' function src/modules/getDisplayValue that should work for all handsontable columns.<br>
 * Valid filter types: contains, not, greater, less, equals<br>
 * If no filter type is given 'contains' is assumed.
 *
 * Columns has to be an array of handsontable columns.
 * https://handsontable.com/docs/Options.html#columns
 *
 * @param needle {String}
 * @param data {Array}
 * @param columns {Array}
 * @param filtersByColumnKey {Object|null}
 * @returns {Array}
 */
export function filterByAllFields(needle = '', data = [], columns = [], filtersByColumnKey = null) {
  if (needle.length === 0 && (filtersByColumnKey == null || Object.keys(filtersByColumnKey).length === 0)) {
    return [];
  }
  const entriesToFilterOut = [];
  data.forEach((entry, index) => {
    // check if the entry contains the needle in ANY column
    let match = columns.some((column) => {
      const displayValue = getDisplayValue(entry, column);
      if (displayValue.length === 0) {
        return false;
      }
      if (Array.isArray(displayValue)) {
        return displayValue.some((element) => element.toLowerCase().includes(needle.toLowerCase()));
      }
      return displayValue.toLowerCase().includes(needle.toLowerCase());
    });
    if (!match) {
      entriesToFilterOut.push({ entry, index });
      return;
    }

    // check if the entry matches all column specific filters too
    if (filtersByColumnKey == null || Object.keys(filtersByColumnKey).length === 0) {
      return;
    }
    match = columns.every((column) => {
      const columnKey = getColumnKey(column);
      if (filtersByColumnKey[columnKey] == null) {
        return true;
      }
      const displayValue = getDisplayValue(entry, column);
      if (Array.isArray(filtersByColumnKey[columnKey].selectedValues)) {
        if (Array.isArray(displayValue)) {
          return displayValue.some((value) => filtersByColumnKey[columnKey].selectedValues.includes(value));
        }
        return filtersByColumnKey[columnKey].selectedValues.includes(displayValue);
      }
      if (typeof filtersByColumnKey[columnKey].value !== 'string' || filtersByColumnKey[columnKey].value.length === 0) {
        return true;
      }
      return compareValuesByFilterType(
        displayValue,
        filtersByColumnKey[columnKey].value,
        filtersByColumnKey[columnKey].type,
      );
    });
    if (!match) {
      entriesToFilterOut.push({ entry, index });
    }
  });

  return entriesToFilterOut;
}
