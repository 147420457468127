
import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { faChevronCircleDown } from '@fortawesome/pro-solid-svg-icons';
import Handsontable from 'handsontable';
import _cloneDeep from 'lodash.clonedeep';
import { ComponentPublicInstance, PropType, defineComponent } from 'vue';

import TableHeaderContextMenu from '@/shared/handsontable/components/TableHeaderContextMenu.vue';
import { HOT_DISPLAY_BUTTON_RENDERER } from '@/shared/handsontable/rework/cellTypes/subtablePrimaryColumn/constants';
import differenceObject from '@/shared/handsontable/rework/features/detectChanges/difference/differenceObject';
import {
  filterTableByPhysicalIndices,
  getFilteredRows,
  resetFilter,
} from '@/shared/handsontable/rework/features/filter';
import { FilterByColumnKey, FilterByDate } from '@/shared/handsontable/rework/features/filter/types';
import getDisplayValue, { getColumnKey } from '@/shared/modules/getDisplayValue';

library.add(faChevronCircleDown, faFilter);

type TableHeaderContextMenuTarget = {
  element: Element;
  sortOrder: Handsontable.columnSorting.SortOrderType | null;
  sortDisabled: boolean;
  column: Handsontable.ColumnSettings;
};

type FilterByColumnKeyDifference = {
  columnKey: string;
  filter: FilterByColumnKey;
};

/**
 * injects a TableHeaderContextMenu component into the table header
 * NOTE: is not compatible with the Handsontable NestedHeaders plugin,
 * because the NestedHeaders plugin clears the header element's innerHTML on each re-render
 * Therefore, the injected MenuIcon will be removed on each re-render
 */
export default defineComponent({
  name: 'TableHeaderContextMenuInjector',
  components: { TableHeaderContextMenu },
  props: {
    tableRef: { type: Object as PropType<ComponentPublicInstance>, required: true },
    columns: { type: Array as PropType<Handsontable.ColumnSettings[]>, required: true },
    filtersByColumnKey: { type: Object as PropType<Record<string, FilterByColumnKey>>, required: true },
  },
  data() {
    return {
      tableHeaderContextMenuTarget: null,
      tableHeaderContextMenuAvailableValues: null,
    } as {
      tableHeaderContextMenuTarget: TableHeaderContextMenuTarget | null;
      tableHeaderContextMenuAvailableValues: string[] | null;
    };
  },
  mounted() {
    this.hot.addHook('afterGetColHeader', this.afterGetColHeaderHook);
    this.hot.addHook('afterScrollHorizontally', this.tableHeaderContextMenuClose);
  },
  computed: {
    hot(): Handsontable {
      if (!('hotInstance' in this.tableRef)) throw new Error('hotInstance is null');

      // @ts-ignore
      return this.tableRef.hotInstance;
    },
    tableHeaderContextMenuFilter(): FilterByColumnKey | null {
      if (this.tableHeaderContextMenuTarget === null) return null;

      const columnKey = getColumnKey(this.tableHeaderContextMenuTarget.column);
      if (this.filtersByColumnKey === null || this.filtersByColumnKey[columnKey] === null) {
        return null;
      }

      return this.filtersByColumnKey[columnKey];
    },
    computedFiltersByColumnKey(): Record<string, FilterByColumnKey> {
      return _cloneDeep(this.filtersByColumnKey);
    },
  },
  watch: {
    computedFiltersByColumnKey: {
      /* must watch a copy of filtersByColumnKey prop,
       * because otherwise newValue and oldValue are always the same when using deep watcher
       */
      handler(
        newFiltersByColumnKey: Record<string, FilterByColumnKey>,
        oldFiltersByColumnKey: Record<string, FilterByColumnKey>,
      ) {
        const differences = this.detectDifferentFilters(oldFiltersByColumnKey, newFiltersByColumnKey);
        differences.forEach(({ columnKey, filter }) => {
          if (filter && !this.allValuesSelected(columnKey, filter)) {
            this.addFilteredIcon(columnKey);
          } else {
            this.removeFilteredIcon(columnKey);
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    detectDifferentFilters(
      oldFiltersByColumnKey: Record<string, FilterByColumnKey>,
      newFiltersByColumnKey: Record<string, FilterByColumnKey>,
    ): FilterByColumnKeyDifference[] {
      const differences: FilterByColumnKeyDifference[] = [];
      const objDifferences = differenceObject(oldFiltersByColumnKey, newFiltersByColumnKey);
      Object.entries(objDifferences).forEach(([propKeys]) => {
        const columnKey = propKeys.split('.')[0];
        const filter = newFiltersByColumnKey[columnKey];
        differences.push({ columnKey, filter });
      });

      return differences;
    },
    /**
     * Remove the set target column. Call this function when TableHeaderContextMenu emits the "close" event.
     */
    tableHeaderContextMenuClose() {
      if (this.tableHeaderContextMenuTarget != null) {
        this.tableHeaderContextMenuTarget.element.classList.remove('table-header-context-menu__icon--clicked');
      }

      this.resetTargetAndAvailableValues();
    },

    /**
     * Renders the icon that opens the context menu on click inside the table header.
     */
    afterGetColHeaderHook(visualCol: number, th: HTMLTableCellElement) {
      const physicalCol = this.hot.toPhysicalColumn(visualCol);
      const column = this.columns[physicalCol];

      if (column == null) return;

      if (column.noHeaderContextMenu) {
        const menuIcons = this.getMenuIcon(th);
        if (menuIcons.length > 0) {
          menuIcons.forEach((menuIcon) => menuIcon.remove());
        }
        return;
      }

      const columnKey = getColumnKey(column);
      const menuIcons = this.getMenuIcon(th, columnKey);
      if (menuIcons.length > 0) {
        // do not add new icon if menuIcon already exists
        return;
      }

      this.addContextMenuIcon(visualCol, column, th);
    },
    addContextMenuIcon(visualCol: number, column: Handsontable.ColumnSettings, th: HTMLTableCellElement) {
      if (!th.firstChild) throw new Error('th.firstChild is null');

      const contextMenuIcon = this.createContextMenuIcon(visualCol, column, th);

      th.firstChild.appendChild(contextMenuIcon);
    },
    createContextMenuIcon(visualCol: number, column: Handsontable.ColumnSettings, th: HTMLTableCellElement) {
      if (!th.firstChild) throw new Error('th.firstChild is null');

      const span = document.createElement('span');
      const columnKey = getColumnKey(column);
      const dataAttributeColumnkey = this.getDataAttributeSafeColumnKey(columnKey);
      span.dataset.columnKey = dataAttributeColumnkey;
      span.addEventListener('click', (e: MouseEvent) => this.tableHeaderContextMenuClick(e, visualCol, column));

      span.classList.add('table-header-context-menu__icon');

      const [chevronCircleDown] = icon({ prefix: 'fas', iconName: 'chevron-circle-down' }).node;
      chevronCircleDown.classList.add('table-header-context-menu__icon-chevron-circle-down');
      span.appendChild(chevronCircleDown);

      const [filter] = icon({ prefix: 'far', iconName: 'filter' }).node;
      filter.classList.add('table-header-context-menu__icon-filter');
      span.append(filter);

      return span;
    },
    /**
     * On click on table header icon sets the target column so that TableHeaderContextMenu component can be rendered.
     */
    async tableHeaderContextMenuClick(e: MouseEvent, visualCol: number, column: Handsontable.ColumnSettings) {
      e.preventDefault();
      e.stopPropagation();
      if (!(e.target instanceof Element)) throw new Error('e.target is not an Element');
      const contextMenuIcon = this.findContextMenuInParentDomElement(e.target);
      if (!contextMenuIcon) throw new Error('Could not find context menu icon element.');

      contextMenuIcon.classList.add('table-header-context-menu__icon--clicked');

      if (this.tableHeaderContextMenuTarget != null) {
        // close previous context menu because clicked on different column
        this.tableHeaderContextMenuTarget.element.classList.remove('table-header-context-menu__icon--clicked');
        const previousColumn = this.tableHeaderContextMenuTarget.column;
        if (previousColumn === column) {
          // close context menu because clicked on same column;
          this.resetTargetAndAvailableValues();
          return;
        }
      }

      const sortOrder = this.getCurrentSortOrder(visualCol);
      const sortDisabled = this.isSortDisabled(visualCol);
      this.tableHeaderContextMenuTarget = {
        element: contextMenuIcon,
        column,
        sortOrder,
        sortDisabled,
      };
      this.tableHeaderContextMenuAvailableValues = this.getAvailableValuesFromColumnData(visualCol, column);
    },
    /**
     * Sorts the table. Call this function when TableHeaderContextMenu emits the "sort" event.
     */
    tableHeaderContextMenuSort(sortOrder: 'asc' | 'desc') {
      if (this.tableHeaderContextMenuTarget === null) return;

      const columnKey = getColumnKey(this.tableHeaderContextMenuTarget.column);
      const column = this.hot.propToCol(columnKey);
      this.hot.getPlugin('columnSorting').sort({ column, sortOrder });

      this.tableHeaderContextMenuClose();
    },
    /**
     * Updates the column filter. Call this function after the user updates the search field in TableHeaderContextMenu component.<br>
     * Valid types: contains, not, exact
     */
    tableHeaderContextMenuUpdateFilter(filter: FilterByColumnKey | null) {
      if (this.tableHeaderContextMenuTarget === null) return;

      const columnKey = getColumnKey(this.tableHeaderContextMenuTarget.column);

      if (filter && !this.allValuesSelected(columnKey, filter)) {
        this.$emit('filtersByColumnKey', columnKey, filter);
      } else {
        this.$emit('filtersByColumnKey', columnKey, null);
      }
    },
    tableHeaderContextMenuUpdateDateFilter(filter: FilterByDate | null) {
      if (this.tableHeaderContextMenuTarget === null) return;
      const columnKey = getColumnKey(this.tableHeaderContextMenuTarget.column);
      if (filter) {
        this.$emit('filtersByDate', { ...filter, columnKey });
      } else {
        this.$emit('filtersByDate', null);
      }
    },
    //
    // helper functions
    //
    findContextMenuInParentDomElement(element: Element | null): Element | null {
      if (element == null) return null;
      if (element.classList.contains('table-header-context-menu__icon')) return element;

      return this.findContextMenuInParentDomElement(element.parentElement);
    },
    getCurrentSortOrder(visualCol: number): 'asc' | 'desc' | null {
      const sortConfig = this.hot.getPlugin('columnSorting').getSortConfig(visualCol);
      if (sortConfig == null) return null;
      return sortConfig.sortOrder;
    },
    isSortDisabled(visualCol: number): boolean {
      const physicalCol = this.hot.toPhysicalColumn(visualCol);
      const column = this.columns[physicalCol];

      if (column.columnSorting === undefined) return false;
      if (column.columnSorting === false) return true;
      if (column.columnSorting === true) return false;

      return column.columnSorting.headerAction === false;
    },
    getAvailableValuesFromColumnData(visualCol: number, column: Handsontable.ColumnSettings) {
      const tableHeaderContextMenuAvailableValues = new Set<string>();

      // undo filtering to get all values and reapply filtering afterwards
      const filteredRows = getFilteredRows(this.hot);
      resetFilter(this.hot);
      const columnData = this.hot.getDataAtCol(visualCol);
      filterTableByPhysicalIndices(this.hot, filteredRows);

      columnData.forEach((entry) => {
        const displayValue = getDisplayValue(entry, column);
        if (displayValue === HOT_DISPLAY_BUTTON_RENDERER) return;
        if (Array.isArray(displayValue)) {
          displayValue.forEach((value) => tableHeaderContextMenuAvailableValues.add(value));
        } else {
          tableHeaderContextMenuAvailableValues.add(displayValue);
        }
      });
      return Array.from(tableHeaderContextMenuAvailableValues);
    },
    resetTargetAndAvailableValues() {
      this.tableHeaderContextMenuTarget = null;
      this.tableHeaderContextMenuAvailableValues = null;
    },
    allValuesSelected(columnKey: string, filter: FilterByColumnKey): boolean {
      const visualCol = this.hot.propToCol(columnKey);
      const physicalCol = this.hot.toPhysicalColumn(visualCol);
      const column = this.columns[physicalCol];
      const availableValues = this.getAvailableValuesFromColumnData(visualCol, column);
      if (!filter.selectedValues) return false;
      return filter.selectedValues.length === availableValues.length;
    },
    addFilteredIcon(columnKey: string) {
      const menuIcons = this.getMenuIcon(this.tableRef.$el, columnKey);
      menuIcons.forEach((menuIcon) => menuIcon.classList.add('table-header-context-menu__icon--filtered'));
    },
    removeFilteredIcon(columnKey: string) {
      const menuIcons = this.getMenuIcon(this.tableRef.$el, columnKey);
      menuIcons.forEach((menuIcon) => menuIcon.classList.remove('table-header-context-menu__icon--filtered'));
    },
    // eslint-disable-next-line no-undef
    getMenuIcon(element: Element, columnKey?: string): NodeListOf<Element> {
      if (columnKey) {
        const dataAttributeColumnkey = this.getDataAttributeSafeColumnKey(columnKey);
        const menuIcons = element.querySelectorAll(`[data-column-key=${dataAttributeColumnkey}]`);
        return menuIcons;
      }

      const menuIcons = element.querySelectorAll('.table-header-context-menu__icon');
      return menuIcons;
    },
    getDataAttributeSafeColumnKey(columnKey: string): string {
      return columnKey.replaceAll('.', '-');
    },
  },
});
