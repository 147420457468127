import { render, staticRenderFns } from "./PageHistory.vue?vue&type=template&id=196015d8&scoped=true&"
import script from "./PageHistory.vue?vue&type=script&setup=true&lang=ts&"
export * from "./PageHistory.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "196015d8",
  null
  
)

export default component.exports