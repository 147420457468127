import type { DaturaDroneAssessmentObjects200Response } from 'farmdok-rest-api';
import Vue from 'vue';
import type { ActionContext } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { ApiResponse } from '@/shared/api/farmdokRestApi/types';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import type { RootState } from '@/store/types';

import { DaturaAssessmentEntry } from './types';

export async function fetchAll({
  rootGetters,
}: ActionContext<SubscribableDataState<DaturaAssessmentEntry>, RootState>): Promise<
  ApiResponse<DaturaAssessmentEntry>
> {
  const { daturaApi } = Vue.prototype.$api as Api;
  const processOrderId = rootGetters['auth/currentProcessOrderIds'][0];
  const promise = await daturaApi.daturaDroneAssessmentList({ processOrderId });
  return toApiResponse(promise.data);
}

function toApiResponse(response: DaturaDroneAssessmentObjects200Response): ApiResponse<DaturaAssessmentEntry> {
  return {
    ...response,
    data: response.data,
  } as ApiResponse<DaturaAssessmentEntry>;
}

async function fetchByIds(): Promise<ApiResponse<DaturaAssessmentEntry>> {
  throw new Error('Unsupported operation!');
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);
