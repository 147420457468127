import { GetterTree } from 'vuex';

import { baseUrl } from '@/shared/constants';
import { SubscribableGetters } from '@/shared/mixins/store/subscribableData/getters';
import { RootState } from '@/store/types';

import type { TableDataAssessment } from '../handsontable/types';
import { subscribableStore } from './common';
import type { DaturaAssessmentEntry, DaturaAssessmentState } from './types';

export type Getters = SubscribableGetters<DaturaAssessmentEntry> & {
  list: DaturaAssessmentEntry[];
  tabular: TableDataAssessment[];
};

const getterTree: GetterTree<DaturaAssessmentState, RootState> = {
  ...subscribableStore.getters,
  list(state: DaturaAssessmentState): DaturaAssessmentEntry[] {
    return Object.values<DaturaAssessmentEntry>(state.data);
  },
  tabular(state: DaturaAssessmentState, getters: Getters): TableDataAssessment[] {
    // TODO: keep the table state in store
    return getters.list.map((e) => ({ ...e, select: false, link: constructLink(e) }));
  },
};

function constructLink(assessment: DaturaAssessmentEntry): string {
  return assessment.files?.length ? `${origin}${baseUrl}datura/drone/viewer/${assessment.files[0].id}` : '';
}

export default getterTree;
